import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue"
import Regulate from "../views/regulate.vue"
import Collection from "../views/collection.vue"
import collAset from "../views/comlist.vue"
import Cloudhall from "../views/Cloudhall.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: Home
  },
  {
    path: '/regulate',
    component: Regulate
  },
  {
   path:"/collection",
   component:Collection
  },
  {
    path:"/aset_path",
    component:collAset
   },
   {
    path:"/Cloudhall",
    component:Cloudhall
   }
]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
})

export default router