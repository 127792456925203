<template>
  <div class="bj">
    <header>
      <h1 style="font-family: Arial, Microsoft YaHei, 黑体, 宋体, sans-serif">
        综合管理平台
      </h1>
      <div class="navlist">
        <!-- <ul>
          <li
            @click="changeselect(1)"
            :style="isShow === 1 ? 'color:#ffcc66' : ''"
          >
            总览
          </li>
          <li
            @click="changeselect(2)"
            :style="isShow === 2 ? 'color:#ffcc66' : ''"
          >
            智慧保护
          </li>
          <li
            @click="changeselect(3)"
            :style="isShow === 3 ? 'color:#ffcc66' : ''"
          >
            智慧管理
          </li>
          <li
            @click="changeselect(4)"
            :style="isShow === 4 ? 'color:#ffcc66' : ''"
          >
            智慧服务
          </li>
        </ul> -->
      </div>
    </header>
    <div class="content container">
      <div class="content_box row">
        <div class="router" v-show="isShow === 1 || isShow === 2">
          <a @click="skip('http://bs.wbzx01.com')">
            <img src="@/assets/image/1661413923177.jpg" />
            <p>环境监测</p>
          </a>
        </div>
        <div class="router" v-show="isShow === 1 || isShow === 2">
          <a @click="toregulate"
            ><img src="@/assets/image/1.7df75a6c.jpg" />
            <p>智能中控</p>
          </a>
        </div>
        <!-- <div class="router" v-show="isShow === 1 || isShow === 4">
          <a @click="skip()"
            ><img src="@/assets/image/4.740124fc.png" />
            <p>客流统计</p></a
          >
        </div> -->
        <div class="router" v-show="isShow === 1 || isShow === 3">
          <a @click="skip('http://art.wbzx01.com/')"
            ><img src="@/assets/image/1005.44637f43.jpg" />
            <p>藏品综合管理</p></a
          >
        </div>
        <!-- <div class="router" v-show="isShow === 1 || isShow === 3">
          <a @click="skip()"
            ><img src="@/assets/image/showcase.d12394c8.png" />
            <p>文物数字资源管理</p></a
          >
        </div> -->
        <!-- <div class="router" v-show="isShow === 1 || isShow === 4">
          <a @click="skip('Cloudhall')"
            ><img src="@/assets/image/2.4c157249.jpg" />
            <p>云展厅服务</p></a
          >
        </div> -->
      </div>
    </div>
    <div class="recordform">
      <!-- <a href=" " target="_blank">津ICP备2021007830</a> -->
      <img/>
      <a style="color:#fff" href="https://beian.miit.gov.cn/" target="_blank">津ICP备2021007830号-1</a>
    </div>
    <div class="botrig">
      以上图标LOGO和行业的版权属于客户群所有,最终解释权归华源彤达
    </div>
    <div class="hint" v-show="hint">正在搭建中...</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hint: false,
      isShow: 1,
    };
  },
  methods: {
    changeselect(item) {
      this.isShow = item;
    },
    toregulate() {
      this.$router.push("/regulate");
    },
    skip(e) {
      if (e === undefined) {
        if (this.hint) {
          return;
        }
        this.hint = true;
        setTimeout(() => {
          console.log(this);
          this.hint = false;
        }, 2000);
      } else if (e === "collect") {
        this.$router.push("/collection");
      } else if (e === "collects") {
        this.$router.push("/aset_path");
      } else if (e === "Cloudhall") {
        this.$router.push("/Cloudhall");
      } else {
        window.location.href = e;
      }
    },
  },
};
</script>
<style scoped>
.bj {
  height: 100%;
}
header {
  width: 100%;
  height: 81px;
  background-image: url("../assets/image/head_bg.png");
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
}
header > h1 {
  text-align: center;
  line-height: 28px;
  font-size: 42px;
  color: #fff;
  width: 100%;
  padding-left: 20px;
}
header .navlist {
  display: flex;
  flex: 1;
}
header .navlist ul {
  width: 47%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20%;
}
header .navlist li {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}
.content {
  height: calc(100% - 180px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.content > .content_box {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.content_box > div {
  margin: 0.5037rem;
  padding: 0px !important;
  cursor: pointer;
  width: 5rem;

  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), inset 0 0 20px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.router > a {
  text-decoration: none;
  color: #000;
}
.router a > img {
  width: 100%;
  height: 3.5rem;
  background-size: 100%;
}
.router > a > p {
  height: 1rem;
  font-size: 0.5rem;
  text-align: center;
  line-height: 1rem;
}
.router > a:hover {
  color: #ffcc66;
}
.router:hover {
  transform: scale(1.1);
}
.hint {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 15px;
  color: #fff;
  font-size: 18px;
  border-radius: 10px;
  transition: all 0.3s;
}
.botrig {
  position: absolute;
  right: 2%;
  bottom: 2%;
}
.recordform {
  position: absolute;
  bottom: 2%;
  display: flex;
  width: 100%;

  justify-content: center;
}
</style>