import Vue from 'vue'
import App from './App.vue'
import router from "./route/index"
import {Carousel,CarouselItem} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.config.productionTip = false
import "@/assets/css/base.css"
import "@/assets/css/css/bootstrap.css"
import "@/assets/font_i5i3sm0ryx/iconfont.css"
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
