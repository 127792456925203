<template>
  <div class="block">
    <div class="back">
      <span @click="back"> ⬅返回</span>
    </div>
    <el-carousel style="height: 100%">
      <el-carousel-item v-for="(item, index) in imgarr" :key="index">
        <div style="height: 100%">
          <img
            :src="item"
            style="width: 100%; height: 100%; object-fit: contain"
          />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgarr: [
        require("../assets/image/aset_home.jpg"),
        require("../assets/image/aset_list.jpg"),
        require("../assets/image/aset_search.jpg"),
      ],
    };
  },
  methods: {
       back(){
    this.$router.push("/")
    }
  },
};
</script>
<style>
.el-carousel__container {
  height: 100% !important;
}
</style>
<style scoped>
/deep/.el-carousel__arrow{
  height: 72px;
  width: 72px;
  font-size: 54px;
}
.block {
  background: #fff;
  height: 100%;
}
.img {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: olive;
}
.back {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  animation: mymove 1s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

/* Standard syntax */
@keyframes mymove {
  50% {
    opacity: 0;
  }
}
@keyframes myshow {
  50% {
    opacity: 1;
  }
}

.back:hover {
  opacity: 1;
  animation: myshow 1s;
}
.back span{
  color: #fff;
  cursor: pointer;
}
</style>