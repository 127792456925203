<template>
  <div class="block">
    <div class="back">
      <span @click="back"> ⬅返回</span>
    </div>
    <iframe
    style="width:100%;height:100%;"
      src="http://quanjing.artron.net/scene/95NypWTaCOoh3BeuPYJaaSfxc8QSIcOB/2018gugongduanmen/tour.html"
    >
    </iframe>
  </div>
</template>
<script>
export default {
  methods: {
    back() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.block {
  background: #fff;
  height: 100%;
  overflow: hidden;
}
.back {
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  animation: mymove 1s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
    scrollbar-width: none; /* firefox */
}

/* Standard syntax */
@keyframes mymove {
  50% {
    opacity: 0;
  }
}
@keyframes myshow {
  50% {
    opacity: 1;
  }
}

.back:hover {
  opacity: 1;
  animation: myshow 1s;
}
.back span{
  color: #fff;
  cursor: pointer;
}
</style>